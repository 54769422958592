/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

// Consider safe area for mobile app
body {
    margin-top: env(safe-area-inset-top);
    margin-right: env(safe-area-inset-right);
    margin-bottom: env(safe-area-inset-bottom);
    margin-left: env(safe-area-inset-left);
  }
  
  // Update safe are color
  html {
    background-color: white;
  }